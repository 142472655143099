html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

h1{
    color: #2b15a8;
    font-size: 64px;
    margin-bottom: 0;
    filter: none;
}

.homeTitle{
    font-size: 64px;
}

.jobTitle{
    clear:both;
    overflow: auto;
}

.floatLeft{
    float: left;
}

h2{
    color: #2b15a8;
    font-size: 32px;
    margin-left: 24px
}

h3{
    color: #2b15a8;
    font-size: 24px;
    float: right;
    margin-right: 24px;
}

a{
    color: #2b15a8;
}

.bTitle{
    display: block;
    height: 1px;
    border: 0;
    margin: auto;
    padding: 0;
    width: 40%;
    overflow: auto;
}

.hTitle {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #2b15a8;
    margin: auto;
    padding: 0;
    width: 40%;
}

.jobInfo{
    font-size: 18px;
    margin-left: 24px;
    margin-top: 0px;
    text-align: left;
    overflow: auto;
}

.exp{
    background-color: hsl(120, 66%, 77%, 0.2);
    margin: 0px 10px 10px 10px;
}

.experience{
    min-height: 100%;
    position: relative;
}

.contactInfo{
    display: absolute;
    background-color: hsl(120, 66%, 77%, 0.2);
    font-size: 24px;
    margin-left: 348px;
    margin-right: 24px;
    margin-top: -500px;
    text-align:left;
}

.ant-layout-content{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.contentIcon{
    margin-top: 128px;
    margin-left: 64px;
  }


/* Project Page Specific */

.projectCard{
    margin: auto;
}

.projectDisplay{
    height: 480px;
}

.pCol{
    margin: auto;
}

.projectTitle{
    margin: auto;
}


/* */

.CS,
.SA, 
homeTitle{
    margin-right: auto;
    margin-left:auto;
    width: 600px;
}

.nameTitle{
    margin-top: 3%;
}

.CS:hover > .Cornell{
    color: red;
}

.SA:hover > .MATLAB{
    color: #ff9f22;
}

.homeTitle:hover > .nick{
    color: rgb(0, 140, 255);
}

.MathWorksLine{
    display:fixed;
}

.hidMAT{
    float: left;
    margin-left: 15%;
    opacity: 0;
}

.hidCorn{
    float: right;
    margin-right: 15%;
    opacity: 0;
}

.hidSun{
    opacity: 0;
}

.SA:hover + .hidMAT {
    opacity: 1;
    animation-name: bounce;
}

.CS:hover + .hidCorn {
    opacity: 1;
    animation-name: jello;
}

.homeTitle:hover ~ .hidSun {
    opacity: 1;
    animation-name: zoomIn;
}